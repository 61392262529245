import { CSLReference } from "@sciflow/cite";
import { counterStyle, DocumentSnapshotResource } from "./sciflow-types";

/**
 * ProseMirror node names
 */
export enum SFNodeType {
    /** the root node */
    document = 'doc',
    /** A wrapper for a chapter or similar */
    section = 'section',
    /** A (book) part that may contain individual sub-chapters */
    part = 'part',
    /** A header containing a heading and a subtitle */
    header = 'header',
    paragraph = 'paragraph',
    text = 'text',
    math = 'math',
    footnote = 'footnote',
    citation = 'citation',
    quote = 'quote',
    poetry = 'poetry',
    note = 'note',
    /** A forced page break */
    pageBreak = 'pageBreak',
    /** An element that will be replaced with something else in production */
    placeholder = 'placeHolder',
    hardBreak = 'hard_break',
    blockquote = 'blockquote',
    label = 'label',
    caption = 'caption',
    code = 'code_block',
    ordered_list = 'ordered_list',
    bullet_list = 'bullet_list',
    /** Links used for cross referencing (@see MarkType.anchor for hyperlinks) */
    link = 'link',
    hyperlink = 'hyperlink',
    list_item = 'list_item',
    heading = 'heading',
    figure = 'figure',
    image = 'image',
    table = 'table',
    table_row = 'table_row',
    table_cell = 'table_cell',
    table_header = 'table_header',
    subtitle = 'subtitle',
    horizontalRule = 'horizontal_rule'
};

export enum SFMarkType {
    emphasis = 'em',
    strong = 'strong',
    superscript = 'sup',
    subscript = 'sub',
    /** an anchor (hyperlink) */
    anchor = 'anchor',
    bdi = 'bdi',
    tags = 'tags'
};

export type rootDoc = SFNodeType.document | SFNodeType.part;
export const isRootDoc = (t: SFNodeType) => ([SFNodeType.document, SFNodeType.part].includes(t));

interface RootDocAttrs {
    id: string;
    direction?: string;
    role?: string;
    schema?: string;
    /** Base level (e.g. for headings) */
    level?: number;
    locale?: string;
    type?: string;
    placement?: string;
    pageBreak?: string;
    /** Chapter numbering */
    numbering?: string;
    /** Page numbering in the section */
    placementNumbering?: string;
    /** An optional part id to reference a node location */
    partId?: string;
    [key: string]: any;
}

/** The simplified manuscript format a document is saved in (including meta data)
 * It only contains a single linear document and is not split into parts
 * like SciFlow documents usually are. This makese it more suited to direct
 * Word import.
*/
export interface SimplifiedManuscriptFile {
    document: DocumentNode<SFNodeType.document>;
    authors: Author[];
    /** The last time the document was saved */
    lastModified: number;
    /** references as CSL */
    references?: CSLReference[];
    /** A list of all files used in the document (e.g. to replace thumbnails with large resolution images in export) */
    files?: DocumentSnapshotResource[];
    metaData?: object;
    template?: string;
}

export interface Author {
    userId?: string;
    email: string;
    roles?: ('owner' | 'author' | 'editor' | 'comment-only' | 'read-only')[];
    rank: number;
    title: string;
    name: string;
    orcid?: string;
    bio?: string;
    authorId: string;
    comment?: string;
    firstName: string;
    lastName: string;
    positions: Position[];
    funders?: Funder[];
    correspondingAuthor: boolean;
    equalContribution: boolean;
    deceased: boolean;
    hideInPublication: boolean;
}

/* export interface File {
    isDirectory?: boolean;
    name?: string;
    mimeType?: string;
    children?: File[];
    content?: {
        blob?: string;
        path?: string;
        url?: string;
    }
} */

export interface Funder {
    id: string;
    /*** ISO 3166-1 2-letter (US,CA,GB,..) */
    countryCode: string;
    institution: {
        id: string;
        name: string;
        type?: 'doi' | 'Ringgold' | 'ISNI';
    };
    awardIds: string[];
}

export interface Position {
    title: string;
    department: string;
    institution: string;
    street: string;
    city: string;
    country: string;
    primary: boolean;
    slug: string;
}

export interface DocumentNode<SFNodeType, AttributeSchema = any> {
    type: SFNodeType;
    attrs?: AttributeSchema;
    content?: DocumentNode<any>[];
    text?: string;
    meta?: any;
    marks?: any[];
}

export interface RootDocumentNode extends DocumentNode<SFNodeType.part | SFNodeType.document> {
    attrs: RootDocAttrs;
}

export interface HeadingDocumentNode extends DocumentNode<SFNodeType.heading> {
    type: SFNodeType.heading;
    attrs: RootDocAttrs;
    text: undefined;
}
